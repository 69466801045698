// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-data-container {
  padding: 12px 16px;
  border: 1px solid rgba(25, 118, 210, 0.12);
  box-shadow: none !important;
  flex-grow: 1;
  display: flex;
  .card-headline {
    color: #063574;
  }
  .inner-card-container {
    padding: 8px;
    box-shadow: none !important;
  }
  .value-card-container {
    background-color: #eceff1;
  }
  .lift-card-profit {
    background-color: #e8f5e9;
    color: #26890d;
  }
  .lift-card-loss {
    background-color: #fbe9e7;
    color: #bf360c;
  }
  .price-name {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
  .price-name-lift {
    font-size: 12px;
    font-weight: 400;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/Output/SalesCard/SalesCard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0CAA0C;EAC1C,2BAA2B;EAC3B,YAAY;EACZ,aAAa;EACb;IACE,cAAc;EAChB;EACA;IACE,YAAY;IACZ,2BAA2B;EAC7B;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;IACzB,cAAc;EAChB;EACA;IACE,yBAAyB;IACzB,cAAc;EAChB;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,yBAAyB;EAC3B;EACA;IACE,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":[".sales-data-container {\n  padding: 12px 16px;\n  border: 1px solid rgba(25, 118, 210, 0.12);\n  box-shadow: none !important;\n  flex-grow: 1;\n  display: flex;\n  .card-headline {\n    color: #063574;\n  }\n  .inner-card-container {\n    padding: 8px;\n    box-shadow: none !important;\n  }\n  .value-card-container {\n    background-color: #eceff1;\n  }\n  .lift-card-profit {\n    background-color: #e8f5e9;\n    color: #26890d;\n  }\n  .lift-card-loss {\n    background-color: #fbe9e7;\n    color: #bf360c;\n  }\n  .price-name {\n    font-size: 12px;\n    font-weight: 400;\n    color: rgba(0, 0, 0, 0.6);\n  }\n  .price-name-lift {\n    font-size: 12px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
