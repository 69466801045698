// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uPM0ldtuFfjNMNInI_Op {
    font-family: Open Sans;
    font-size: 25px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: center;
    color: #063574;
}`, "",{"version":3,"sources":["webpack://./src/styles/ChooseOptimization.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".stepHeading {\n    font-family: Open Sans;\n    font-size: 25px;\n    font-weight: 400;\n    line-height: 33px;\n    letter-spacing: 0px;\n    text-align: center;\n    color: #063574;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepHeading": `uPM0ldtuFfjNMNInI_Op`
};
export default ___CSS_LOADER_EXPORT___;
