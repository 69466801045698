// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-table-container {
  max-height: inherit !important;
  .model-table-header {
    height: 48px;
    margin-bottom: 8px;
    background-color: "#0E336A0A";
    .header-label {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .model-table-body {
    .model-table-row {
      border: 1px solid #cfd8dc;
      border-radius: 4px;
      height: 62px;
    }
    .table-loss-value {
      color: #bf360c;
    }
    .table-profit-value {
      color: #26890d;
    }
  }
}
.model-table-pagination {
  height: 56px;
  padding: 8px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModelTable/ModelTable.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B;IACE,YAAY;IACZ,kBAAkB;IAClB,6BAA6B;IAC7B;MACE,eAAe;MACf,gBAAgB;IAClB;EACF;EACA;IACE;MACE,yBAAyB;MACzB,kBAAkB;MAClB,YAAY;IACd;IACA;MACE,cAAc;IAChB;IACA;MACE,cAAc;IAChB;EACF;AACF;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".model-table-container {\n  max-height: inherit !important;\n  .model-table-header {\n    height: 48px;\n    margin-bottom: 8px;\n    background-color: \"#0E336A0A\";\n    .header-label {\n      font-size: 14px;\n      font-weight: 600;\n    }\n  }\n  .model-table-body {\n    .model-table-row {\n      border: 1px solid #cfd8dc;\n      border-radius: 4px;\n      height: 62px;\n    }\n    .table-loss-value {\n      color: #bf360c;\n    }\n    .table-profit-value {\n      color: #26890d;\n    }\n  }\n}\n.model-table-pagination {\n  height: 56px;\n  padding: 8px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
