// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tXz5vJbaJKH3Lgo5SJRR {
  width: 99px;
  display: flex;
  justify-content: start;
}
.mC06gEHY6FIT6gSzYF2w {
  color: var(--light-text-primary, rgba(0, 0, 0, 0.87));
  /* Components/Button Small */
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 169.231% */
  letter-spacing: 0.46px;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/styles/BackButton.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,qDAAqD;EACrD,4BAA4B;EAC5B,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,sBAAsB;EACtB,yBAAyB;AAC3B","sourcesContent":[".backButton {\n  width: 99px;\n  display: flex;\n  justify-content: start;\n}\n.backButtonText {\n  color: var(--light-text-primary, rgba(0, 0, 0, 0.87));\n  /* Components/Button Small */\n  font-family: Open Sans;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 22px; /* 169.231% */\n  letter-spacing: 0.46px;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": `tXz5vJbaJKH3Lgo5SJRR`,
	"backButtonText": `mC06gEHY6FIT6gSzYF2w`
};
export default ___CSS_LOADER_EXPORT___;
