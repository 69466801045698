// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-level-constraint-container{
    margin: 8px;
    .price-slider{
        height: 150px;
        margin: 8px 0px;
    }
    .level-label{
        font-size: 14px;
        margin-bottom: 8px;
    }
    .per-value-container{
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/SliderIndicator/SliderIndicator.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX;QACI,aAAa;QACb,eAAe;IACnB;IACA;QACI,eAAe;QACf,kBAAkB;IACtB;IACA;QACI,gBAAgB;QAChB,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;AACJ","sourcesContent":[".slider-level-constraint-container{\n    margin: 8px;\n    .price-slider{\n        height: 150px;\n        margin: 8px 0px;\n    }\n    .level-label{\n        font-size: 14px;\n        margin-bottom: 8px;\n    }\n    .per-value-container{\n        margin-top: 16px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
