export var constants = {
    // -------------------------Title bar------------------
    create_optimization: "CREATE OPTIMIZATION",
    price_optimization_title: "Price Optimization",
    edit_optimization_name: "Provide a name for the optimization",
    continue: "CONTINUE",
    update: "UPDATE",
    download_button_output: "DOWNLOAD",
    no_optimization: "No optimizations created yet. ",
    return_home: "RETURN HOME",
    create_optimization_heading: "Create Optimization",
    default_setting: "Default Settings",
    home: "Home",
    //main actions
    save_and_exit: "SAVE AND EXIT",
    save: "SAVE",
    choose_discard: "DISCARD",
    download: "DOWNLOAD LIST",
    //second actions
    go_back: "GO BACK",
    cancel: "CANCEL",
    choose_yes_go_back: "yes, go back",
    choose_stay_here: "No, Stay Here",
    timeframe: "When should the optimization be in the market?",
    //third actions
    next_define_details: "NEXT: DEFINE DETAILS",
    next_review: "NEXT: REVIEW",
    apply_constraints: "APPLY CONSTRAINTS",
    complete: "COMPLETE",
    create: "CREATE",
    //download actions
    download_list: "DOWNLOAD LIST",
    download_constraint: "DOWNLOAD",
    // Header Stepper Labels
    choose_opt: "Choose Optmization",
    define_details: "Define Details",
    review_complete: "Review & Complete",
    // Choose Optimizatiom
    choose_title: "What do you want to optimize?",
    choose_cancel: "Cancel",
    choose_settings: "Settings",
    choose_exit: "exit",
    choose_next: "NEXT",
    choose_exist_optimization: "Do you want to copy the configs from an existing optimization?",
    optimization_name_title: "Optimization name",
    limit_exceed_error: "You have exceeded the maximum character limit of 65",
    name_exist: "This name already exists. Use a unique name for this optimization.",
    duplicate_copy_name_error: "This name already exists. Use a unique name for this scenario.",
    choose_optimization_use_case: "What is your optimization use case?",
    scenarios: "Scenarios",
    // Create Optimization
    // Select Datasets
    select_dataset: "Select or Upload Datasets",
    dataset_subtitle: "Which datasets will be used?",
    dataset_select: "Select",
    dataset_upload: "Upload",
    dataset_cancel: "Cancel",
    dataset_next: "Next",
    dataset_edit: "Edit",
    exit: "Exit",
    back: "Back",
    review: "next: Run",
    upload_failed: "Upload Failed",
    file: "File",
    preview: "Preview",
    file_name: "File Name",
    step: "Step",
    upload_your_data: "Upload Your Data",
    //dropdown Status
    Name_AZ: "Name (A-Z)",
    Name_ZA: "Name (Z-A)",
    Date_Created_New_Old: "Date Created (New-Old)",
    Date_Created_Old_New: "Date Created (Old-New)",
    No_Scenarios_Ascending: "No Scenarios (Ascending)",
    No_Scenarios_Descending: "No Scenarios (Descending)",
    sort_by: "Sort by",
    status: "Status",
    // data details
    data_details: "Data Details",
    available_data: "Available data fields",
    drag_and_Drop_instruction: "Drag and drop the following data fields into the correct boxes",
    field_one_unique_id: "Unique ID, Item Description, and Time",
    //settings
    general: "General",
    quantity_sold: "Quantity Sold",
    gross_profit: "Gross Profit",
    gross_sales: "Gross Sales",
    // Optimization details
    opt_details: "Optimization Details",
    Optimization: "Optimization ",
    create_scenario: "Create Scenario",
    choose_exist_scenario: "Do you want to copy the configs from an existing scenario?",
    scenario_name_title: "Scenario name",
    what_to_maximize: "What do you want to maximize?",
    what_to_optimize: "What do you want to optimize?",
    segment: "Segment",
    segment_tooltip: "Do you want the optimization to separate the problem into sub-problems? The available options are informed by the hierarchy dataset that was uploaded.",
    segment_desc: "Do you want to segment the optimization?",
    no: "No",
    yes: "Yes",
    expert_setting: "Expert settings",
    no_of_iterations: "Number of iterations",
    iteration_tooltip: "The higher the number, the longer the run time. If you are testing a new scenario, it is recommended to set this setting to be less than 3.",
    no_of_iterations_desc: "How many times the optimization should re-start looking for the best solution. The higher the number , the longer the run time",
    iteration_note: "Note: the higher the number, the longer the run time.",
    when_to_optimize: "When should the optimization be in the market?",
    constraints: "Set price optimization constraints",
    download_template: "Download Template",
    timeframe_tooltip: "This is when prices will be active in the market. The minimum and maximum dates available for you to select is set by the Forecast dataset that was uploaded.",
    upload_constraints: "Upload constraints",
    // Landing Page
    proposal_lift: "PROPOSAL LIFT ",
    sales: "Sales",
    id: "ID",
    decision: "Decisions",
    scope: "Scope",
    timeframeTitle: "Timeframe",
    //process screen
    process_message: "Optimizing prices... this process may take a few minutes",
    close: "CLOSE",
    loading: "Loading...",
    //Empty state text
    empty_state: "The configs will be available when the Select Dataset step is completed",
    //output screen
    scenario_details: "Scenario Details",
    price_change_output: "Current Price",
    optimize_price: "Optimized Price",
    change: "Change",
    congratulation: "Congratulations!",
    completeOptPara: "The optimization is now complete and ready to be downloaded.",
    //optimization dashboard
    edit_modal_title: "Rename Optimization",
    edit_modal_label: "New Optimization Name",
    optimization_settings_labeltab0: "OVERVIEW & DATASETS",
    optimization_settings_labeltab1: "DATA FIELDS MAPPING",
    optimization_settings_warning: "If you wish to change any of the settings below, you must create a new optimization",
    overview_dataset_title: "Input Data",
    overview_dataset_field_two: "When should the optimization be in the market?",
    overview_dataset_field_three: "Elasticity",
    overview_dataset_field_four: "Hierarchy",
    data_fields_mapping_title: "Map data fields",
    data_fields_mapping_section_one: "Unique ID, Item Description and Time",
    data_fields_mapping_section_two: "Financial Fields",
    data_fields_mapping_section_three: "Promotion Field",
    data_fields_mapping_section_four: "Price Fields",
    new_scenario: "Create New Scenario",
    create_new_optimization: "Create New Optimization",
    rename: "Rename",
    archive: "Archive",
    delete: "Delete",
    created: "Created:",
    number_of_scenarios: "No. of scenarios:",
    status_of_archive: "Yes",
    status_of_unarchive: "No",
    unArchive: "Unarchive",
    clear_all_fields: "clear all fields",
    review_default: "review default",
    view_scenario: "View scenario",
    compare: "Compare",
    constraintSummary: "Summary: ",
    //constraints details
    title: "Constraints",
    subtitle: "These constraints will be applied within each segment",
    input_highLevel_constaints: "Input High Level Constraints",
    upload_constraint: "Upload Constraint File",
    //high level constraints
    item_level_constraints: "Item Level Constraints",
    segment_level_constraints: "Segment Level Constraints",
    price_ending_values: "Price Ending Values",
    ending_in: "Ending In:",
    select_allowed_value: "Select allowed price ending values",
    incremented_by: "Incremented By:",
    example_price_endings: "Example Price Endings:",
    //settings board
    map_data_feilds: "Map Data Fields",
    column_selection: "Select the correct column name for each of the fields below",
    default_settings: "Default Settings",
    financial_feilds: "Financial Fields",
    global_default_text: "Set up the global defaults for all optimizations. You will have the flexibility to override these settings should that be required.",
    price_promotion_feilds: "Price and Promotion Fields",
    price_feilds: "Price Feilds",
    hierarchy_time_feilds: " Hierarchy and Time Fields",
    unique_id_time: "Unique ID, Item Description, and Time'",
    time_feilds: "Time Feilds",
    product_hierarchy_feilds: "Product Hierarchy Fields",
    title_multiSelct_heirarchy: "All of the product hierarchy fields (category, subcategory, brand, etc.)",
    store_hierarchy_feils: "All of the store hierarchy fields (store address, region, zone, etc.)",
    channel: "Channel",
    product_feilds: "Product Feilds",
    //competitor prices
    competitor_price: "Competitor Prices",
    optional_text: "(Optional)",
    subject: "List of competitor prices. These prices will only be used in reporting. If you would like to apply water level constraints, the constraint template must be used.",
    review_settings: "Review settings",
    default_review_settings: "Before you are able to create an optimization, you must configure the settings.",
    name_A_Z: "Name(A-Z)",
    //output dashboard
    proposal_summary: "Proposal Summary",
    units: "Units",
    constraints_label: "Constraints",
    performance_over_time: "Performance over time",
    performance_breakdown: "Performance Breakdown",
    summarized_by: "Summarized By",
    filter_by: "Filter By",
    proposed_price_changes: "Proposed Price Changes",
    select_row_for_details: "Select a row to see more details",
    ConstraintID: "Constraint ID",
    Constraint: "Constraint",
    PassOrFail: "Pass or Fail",
    // Impacts on Complement and Substitute Items
    ID: "ID",
    Item_Description: "Item Description",
    Complement_Substitute: "Complement / Substitute ",
    Cross_Elasticity: "Cross Elasticity",
    Units_Given: "Units Given (+) or Taken (-) from Item",
    table_id: "ID",
    table_product: "Product",
    table_current_price: "Current Price",
    table_perc_change: "% Change",
    table_optimized_price: "Optimized Price",
    table_gross_sales_impact: "Gross Sales Impact",
    table_gross_profit_impact: "Gross Profit Impact",
    table_units_impact: "Units Impact",
    table_category: "Category",
    table_gross_profit: "Gross Profit Lift %",
    table_sales: "Gross Sales Lift %",
    table_units: "Units Lift %",
    table_pass_percentage: "Constraints - Pass %",
    passed: "Passed",
    optimised_prices: "Optimized Prices",
    lift_perc: "Lift %",
    current_prices: "Current Prices",
    //product drawer
    why_change_price: "Why change the price?",
    proposed_price_change: "Proposed price change:",
    complement_substitute: "Complement and substitute impacts:",
    financial_snapshot: "Financial Snapshot",
    unit_impact_price_change: "Unit Impact Due to Price Changes",
    pdf: "PDF",
    competitor_insights: "Competitor Insights",
    impacts_complement_substitue: "Impacts on Complement and Substitute Items",
    no_constraints: "No Constraints",
    no_competitor_insights: "No Competitor Insights",
    no_impacts_on_complement_and_substitute_items: "No Impacts on Complement and Substitute Items"
};
