// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nZhSyXijcwNRkbPZBS7A {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.C7b3jPltni0_K1LKD8mH {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
}

.MULdWKe9fj0tl1v1ecvm {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;

}

.SJT95ioAqEM4kTN3jURE {
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/ErrorModal.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,qCAAqC;IACrC,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,qCAAqC;IACrC,kBAAkB;;AAEtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".btnStyle {\n    margin-top: 32px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    width: 100%;\n}\n\n.modalTitle {\n    font-family: Open Sans;\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 32px;\n    letter-spacing: 0.15000000596046448px;\n    text-align: center;\n}\n\n.detail {\n    font-family: Open Sans;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: 0.15000000596046448px;\n    text-align: center;\n\n}\n\n.container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnStyle": `nZhSyXijcwNRkbPZBS7A`,
	"modalTitle": `C7b3jPltni0_K1LKD8mH`,
	"detail": `MULdWKe9fj0tl1v1ecvm`,
	"container": `SJT95ioAqEM4kTN3jURE`
};
export default ___CSS_LOADER_EXPORT___;
