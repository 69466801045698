// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bodySection {
    display: flex;
    min-height: 669px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
}

.iconSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.emptySection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.iconContainer {
    position: relative;
    width: 300px;
    height: 300px;
    flex-shrink: 0;
    border-radius: 34px;
    background: linear-gradient(136deg, #1697A8 30.02%, #1BDCD0 96.37%);
}

.icon {
    position: absolute;
    top: 15%;
    left:15%;
}`, "",{"version":3,"sources":["webpack://./src/views/Home/DefaultView/DefaultView.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,mEAAmE;AACvE;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,QAAQ;AACZ","sourcesContent":[".bodySection {\n    display: flex;\n    min-height: 669px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 16px;\n    flex-shrink: 0;\n    align-self: stretch;\n}\n\n.iconSection {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 24px;\n}\n\n.emptySection {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.iconContainer {\n    position: relative;\n    width: 300px;\n    height: 300px;\n    flex-shrink: 0;\n    border-radius: 34px;\n    background: linear-gradient(136deg, #1697A8 30.02%, #1BDCD0 96.37%);\n}\n\n.icon {\n    position: absolute;\n    top: 15%;\n    left:15%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
