var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ConfigContext } from "../views/ConfigContext";
import { useContext, useEffect, useState } from "react";
// Custom hook for managing scenarios
export var useScenarios = function () {
    var _a = useContext(ConfigContext), configState = _a.configState, setConfigState = _a.setConfigState;
    var _b = useState(configState.scenario), scenarios = _b[0], setScenarios = _b[1];
    useEffect(function () {
        setScenarios(configState.scenario);
    }, [configState.scenario]);
    useEffect(function () {
        var temp = JSON.parse(JSON.stringify(configState));
        temp.scenario = scenarios;
        setConfigState(temp);
    }, [scenarios]);
    var addScenario = function (newScenario) {
        setScenarios(function (prevScenarios) { return __spreadArray(__spreadArray([], prevScenarios, true), [newScenario], false); });
    };
    var updateScenario = function (updatedScenario) {
        setScenarios(function (prevScenarios) { return prevScenarios.map(function (scenario) { return scenario.scenarioId === updatedScenario.scenarioId ? updatedScenario : scenario; }); });
    };
    var deleteScenario = function (scenarioId) {
        setScenarios(function (prevScenarios) { return prevScenarios.filter(function (scenario) { return scenario.scenarioId !== scenarioId.toString(); }); });
    };
    var getSelectedScenarios = function () {
        return scenarios === null || scenarios === void 0 ? void 0 : scenarios.filter(function (scenario) { return scenario.selected && scenario.status != "failed"; });
    };
    var getFailedSelectedScenarios = function () {
        return scenarios === null || scenarios === void 0 ? void 0 : scenarios.filter(function (scenario) { return scenario.selected && scenario.status == "failed"; });
    };
    var getScenarioById = function (scenarioId) {
        return scenarios === null || scenarios === void 0 ? void 0 : scenarios.filter(function (scenario) { return scenario.scenarioId === scenarioId.toString(); });
    };
    return {
        scenarios: scenarios,
        setScenarios: setScenarios,
        addScenario: addScenario,
        updateScenario: updateScenario,
        deleteScenario: deleteScenario,
        getSelectedScenarios: getSelectedScenarios,
        getScenarioById: getScenarioById,
        getFailedSelectedScenarios: getFailedSelectedScenarios
    };
};
