// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#selectGreenMultiple .MuiOutlinedInput-notchedOutline {
    border-color: #26890D;
}

#selectRedMultiple .MuiOutlinedInput-notchedOutline {
    border-color: #E65100;
}

#selectGreenMultiple .MuiOutlinedInput-notchedOutline:focus-visible {
    outline: #26890D auto 0.3px;
}

#selectRedMultiple .MuiOutlinedInput-notchedOutline:focus-visible {
    outline: #E65100 auto 0.3px;
}

#selectGreenMultiple {
    width: 100%;
}

#selectRedMultiple {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/MultiSelectDropdown.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":["#selectGreenMultiple .MuiOutlinedInput-notchedOutline {\n    border-color: #26890D;\n}\n\n#selectRedMultiple .MuiOutlinedInput-notchedOutline {\n    border-color: #E65100;\n}\n\n#selectGreenMultiple .MuiOutlinedInput-notchedOutline:focus-visible {\n    outline: #26890D auto 0.3px;\n}\n\n#selectRedMultiple .MuiOutlinedInput-notchedOutline:focus-visible {\n    outline: #E65100 auto 0.3px;\n}\n\n#selectGreenMultiple {\n    width: 100%;\n}\n\n#selectRedMultiple {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
