export var sortData = function (optimizationData, val) {
    switch (val) {
        case "Name(A-Z)":
            return optimizationData.sort(function (a, b) { return a.resource.name.localeCompare(b.resource.name); });
        case "Name(Z-A)":
            return optimizationData.sort(function (a, b) { return b.resource.name.localeCompare(a.resource.name); });
        case "Date Created(New-Old)":
            return optimizationData.sort(function (a, b) { return new Date(b.resource.meta.created).getTime() - new Date(a.resource.meta.created).getTime(); });
        case "Date Created(Old-New)":
            return optimizationData.sort(function (a, b) { return new Date(a.resource.meta.created).getTime() - new Date(b.resource.meta.created).getTime(); });
        case "No Scenarios(Ascending)":
            return optimizationData.sort(function (a, b) { return a.resource.numOfScenarios - b.resource.numOfScenarios; });
        case "No Scenarios(Descending)":
            return optimizationData.sort(function (a, b) { return b.resource.numOfScenarios - a.resource.numOfScenarios; });
        default:
            return optimizationData;
    }
};
