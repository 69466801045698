var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { errorHandling } from "../errorhandler";
import axios from "axios";
import { setLoader } from "../app";
var API = /** @class */ (function () {
    function API(config) {
        this.config = config;
        var service = axios.create({
            baseURL: config.apiRoot
        });
        service.interceptors.request.use(function (config) {
            var token = "Bearer " + localStorage.getItem("access_token");
            config.headers.Authorization = token;
            setLoader(true);
            return config;
        });
        service.interceptors.response.use(function (response) {
            setLoader(false);
            return response;
        }, function (error) {
            setLoader(false);
            return Promise.reject(error);
        });
        this.instance = service;
    }
    /**
     * upload different datasets files to GCS via server
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // POST Dataset File upload
    API.prototype.uploadDatasetFile = function (fileDetails) {
        return __awaiter(this, void 0, void 0, function () {
            var response, url, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        response = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        url = "".concat(this.config.apiRoot, "/optimization/dataset");
                        return [4 /*yield*/, this.instance.post(url, fileDetails)];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 3:
                        error_1 = _a.sent();
                        return [2 /*return*/, error_1.response.data];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // GET Dataset selection and retrive properties
    API.prototype.getDatasetList = function (paramString) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/optimization/dataset?").concat(paramString);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_2 = _a.sent();
                        errorHandling(error_2, "getDatasetList", [paramString]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    //Get Optimization Data
    API.prototype.getOptimizationData = function (paramString) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization?").concat(paramString);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_3 = _a.sent();
                        errorHandling(error_3, "getOptimizationData", [paramString]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getOptimizationDataForArchived = function (paramString) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization?").concat(paramString, "&includeArchiveOptmization=true");
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_4 = _a.sent();
                        errorHandling(error_4, "getOptimizationData", [paramString]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getSingleOptimizationData = function (paramString) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/").concat(paramString);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_5 = _a.sent();
                        errorHandling(error_5, "getSingleOptimizationData", [paramString]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    //Save & Exit optimization data
    API.prototype.saveOptimization = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization");
                        return [4 /*yield*/, this.instance.post(url, data)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_6 = _a.sent();
                        errorHandling(error_6, "saveOptimization", [data]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    //check Master Data
    API.prototype.checkMaster = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization?id=MasterId");
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_7 = _a.sent();
                        if (error_7.response.status != 404) {
                            errorHandling(error_7, "check Master Data", []);
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    //Update & Exit optimization data
    API.prototype.updateOptimization = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/").concat(data === null || data === void 0 ? void 0 : data.id);
                        return [4 /*yield*/, this.instance.put(url, data)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_8 = _a.sent();
                        errorHandling(error_8, "updateOptimization", [data]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.archiveUnarchive = function (data, archived) {
        return __awaiter(this, void 0, void 0, function () {
            var _data$resource, url, response, error_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/").concat(data === null || data === void 0 || (_data$resource = data.resource) === null || _data$resource === void 0 ? void 0 : _data$resource.id, "?archive=").concat(archived);
                        return [4 /*yield*/, this.instance.post(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_9 = _a.sent();
                        errorHandling(error_9, "updateOptimization", [data]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    //update in dashboard in complete
    API.prototype.updateOptimizationComplete = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/").concat(data.data.id);
                        return [4 /*yield*/, this.instance.put(url, data.data)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_10 = _a.sent();
                        errorHandling(error_10, "updateOptimization", [data]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getDownloadUrl = function (fileName, type) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_11;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/optimization/dataset/download?fileName=").concat(fileName, "&fileType=").concat(type);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_11 = _a.sent();
                        errorHandling(error_11, "getDownloadUrl", [fileName, type]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getDeleteOptimization = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_12;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/").concat(id);
                        return [4 /*yield*/, this.instance.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_12 = _a.sent();
                        errorHandling(error_12, "getDeleteOptimization", [id]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getDeleteScenario = function (optiId, id) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_13;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/").concat(optiId, "?scenarioId=").concat(id);
                        return [4 /*yield*/, this.instance.delete(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_13 = _a.sent();
                        errorHandling(error_13, "getDeleteScenario", [optiId, id]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getOptimizationById = function (id, isArchive) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_14;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "";
                        if (isArchive) {
                            url = "".concat(this.config.apiRoot, "/Optimization?id=").concat(id, "&includeArchiveScenario=true");
                        }
                        else {
                            url = "".concat(this.config.apiRoot, "/Optimization?id=").concat(id, "&includeArchiveScenario=false");
                        }
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data.entry[0]];
                    case 2:
                        error_14 = _a.sent();
                        errorHandling(error_14, "getOptimizationById", [id, isArchive]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.postScenarioArchive = function (optiID, id, isArchive) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_15;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "";
                        if (isArchive) {
                            url = "".concat(this.config.apiRoot, "/Optimization/").concat(optiID, "?scenarioId=").concat(id, "&archive=true");
                        }
                        else {
                            url = "".concat(this.config.apiRoot, "/Optimization/").concat(optiID, "?scenarioId=").concat(id, "&archive=false");
                        }
                        return [4 /*yield*/, this.instance.post(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_15 = _a.sent();
                        errorHandling(error_15, "postScenarioArchive", [optiID, id, isArchive]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // output screen
    // trigger call from create opt to process page
    API.prototype.triggerOptimizationData = function (data, optimizationId, scenarioId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_16;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/run?id=").concat(optimizationId, "&scenarioId=").concat(scenarioId);
                        return [4 /*yield*/, this.instance.post(url, data)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 2:
                        error_16 = _a.sent();
                        errorHandling(error_16, "triggerOptimizationData", [data, optimizationId, scenarioId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // process status get call
    API.prototype.getOptimizationRunStatus = function (id, scenarioId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_17;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/status?id=").concat(id, "&scenarioId=").concat(scenarioId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_17 = _a.sent();
                        errorHandling(error_17, "getOptimizationRunStatus", [id, scenarioId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getProposalSummaryPriceChange = function (id, scenarioId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_18;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/result/").concat(id, "?element=proposalSummary,proposedPriceChanges&scenarioId=").concat(scenarioId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_18 = _a.sent();
                        errorHandling(error_18, "getProposalSummaryPriceChange", [id, scenarioId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getPerformanceBreakDown = function (id, scenarioId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_19;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/result/").concat(id, "?element=performanceBreakdown&scenarioId=").concat(scenarioId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_19 = _a.sent();
                        errorHandling(error_19, "getPerformanceBreakDown", [id, scenarioId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getPerformanceOverTime = function (id, scenarioId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_20;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/result/").concat(id, "?element=performanceOverTime&scenarioId=").concat(scenarioId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_20 = _a.sent();
                        errorHandling(error_20, "getPerformanceOverTime", [id, scenarioId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getConstraintsData = function (id, scenarioId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_21;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/result/").concat(id, "?element=constraints&scenarioId=").concat(scenarioId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_21 = _a.sent();
                        errorHandling(error_21, "getConstraintsData", [id, scenarioId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getCompSubstituteImpactData = function (id, scenarioId, modelId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_22;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/result/").concat(id, "?element=compSubstituteImpact&scenarioId=").concat(scenarioId, "&modelId=").concat(modelId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_22 = _a.sent();
                        errorHandling(error_22, "getCompSubstituteImpactData", [id, scenarioId, modelId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getConstraintData = function (id, scenarioId, modelId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_23;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/result/").concat(id, "?element=constraints&scenarioId=").concat(scenarioId, "&modelId=").concat(modelId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_23 = _a.sent();
                        errorHandling(error_23, "getConstraintData", [id, scenarioId, modelId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getCompetitorInsightsData = function (id, scenarioId, modelId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_24;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/result/").concat(id, "?element=compInsights&scenarioId=").concat(scenarioId, "&modelId=").concat(modelId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_24 = _a.sent();
                        errorHandling(error_24, "getCompetitorInsightsData", [id, scenarioId, modelId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getProductHierarchyDataData = function (id, scenarioId, modelId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_25;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/result/").concat(id, "?element=productHierarchy&scenarioId=").concat(scenarioId, "&modelId=").concat(modelId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_25 = _a.sent();
                        errorHandling(error_25, "getProductHierarchyDataData", [id, scenarioId, modelId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    API.prototype.getModalDetails = function (id, scenarioId, modalId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_26;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/result/").concat(id, "?element=modelDetails&modelId=").concat(modalId, "&scenarioId=").concat(scenarioId);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_26 = _a.sent();
                        errorHandling(error_26, "getModalDetails", [id, scenarioId, modalId]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    //get the existing the scenario details
    API.prototype.getScenarioDetails = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, error_27;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "".concat(this.config.apiRoot, "/Optimization/").concat(id);
                        return [4 /*yield*/, this.instance.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_27 = _a.sent();
                        errorHandling(error_27, "getScenarioDetails", [id]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return API;
}());
export default API;
