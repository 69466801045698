// Formats a string with title case.
// @param {*} value The string eg. "active Customers"
// @returns {string} Title cased string eg. "Active Customers"
export function TitleCase(value) {
    return value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
// Formats a string by replacing _ with space.
// @param {*} value The string eg. "active_customers"
// @returns {string} Title cased string eg. "active customers"
export function AddUnderscoreSpace(value) {
    return value.replace(/_/g, ' ');
}
// Formats a string by replacing camelcase with space and capitalize.
export function camelCaseToCapitalizedSpace(value) {
    var spacedString = '';
    // Insert a space before each capital letter and convert to lowercase
    if (typeof value == 'object') {
        spacedString = value[0].replace(/([A-Z])/g, ' $1').toLowerCase();
    }
    else {
        spacedString = value.replace(/([A-Z])/g, ' $1').toLowerCase();
    }
    // Capitalize the first letter of each word
    var capitalizedString = spacedString.replace(/(^|\s)\S/g, function (match) {
        return match.toUpperCase();
    });
    return capitalizedString;
}
// Format current date for runId
export function formatDate(date) {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    var hours = date.getHours().toString().padStart(2, '0');
    var minutes = date.getMinutes().toString().padStart(2, '0');
    var seconds = date.getSeconds().toString().padStart(2, '0');
    var milliseconds = date.getMilliseconds().toString().padStart(3, '0');
    return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hours, "-").concat(minutes, "-").concat(seconds, "-").concat(milliseconds);
}
export function camelCaseToNormalString(data) {
    var result = data.replace(/([A-Z])/g, ' $1');
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}
export function removeDuplicate(data) {
    var jsonObject = data.map(JSON.stringify);
    var uniqueSet = new Set(jsonObject);
    var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    return uniqueArray;
}
export function getCategoryColor(value) {
    switch (value) {
        case 'Price':
            return '#009688';
        case 'Product':
            return '#007CB0';
        case 'Promo':
            return '#AB47BC';
        case 'Place':
            return '#26890D';
    }
}
export function getCategoryBackgroundColor(value) {
    switch (value) {
        case 'Price':
            return '#E0F2F1';
        case 'Product':
            return '#E1F5FE';
        case 'Promo':
            return '#F3E5F5';
        case 'Place':
            return '#E8F5E9';
    }
}
export function convertToTitleCase(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
        .replace(/\b\w/g, function (firstChar) { return firstChar.toUpperCase(); }); // Capitalize the first letter of each word
}
