import { constants } from "../../../constants";
export var statusOption = [{
        label: constants.Name_AZ,
        value: "Name(A-Z)"
    }, {
        label: constants.Name_ZA,
        value: "Name(Z-A)"
    }, {
        label: constants.Date_Created_New_Old,
        value: "Date Created(New-Old)"
    }, {
        label: constants.Date_Created_Old_New,
        value: "Date Created(Old-New)"
    }, {
        label: constants.No_Scenarios_Ascending,
        value: "No Scenarios(Ascending)"
    }, {
        label: constants.No_Scenarios_Descending,
        value: "No Scenarios(Descending)"
    }];
