// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RVWVMWcedds8q1Jyd2Hs {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 25px;
  margin-top: 16px;
}

.u_xVBMuNcAYvppDaYSCT {
  flex-grow: 1;
  width: 100%;
  justify-content: start;
  align-items: center;
}

.LwJit1dfshrv1ToA5k7g {
  flex-grow: 1;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 32px;
}

.lITyfaJsDYjVxpVosTiX {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
}

.AIB6RoC4Wx2fUrHcxyR5 {
  margin-left: 8px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/styles/EditModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".iconButton {\n  position: absolute;\n  top: 0;\n  right: 0;\n  margin-right: 25px;\n  margin-top: 16px;\n}\n\n.textStack {\n  flex-grow: 1;\n  width: 100%;\n  justify-content: start;\n  align-items: center;\n}\n\n.textField {\n  flex-grow: 1;\n  width: 100%;\n  margin-top: 40px;\n  margin-bottom: 32px;\n}\n\n.actionstStack {\n  display: flex;\n  flex-direction: row;\n  justify-content: right;\n  width: 100%;\n}\n\n.action {\n  margin-left: 8px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": `RVWVMWcedds8q1Jyd2Hs`,
	"textStack": `u_xVBMuNcAYvppDaYSCT`,
	"textField": `LwJit1dfshrv1ToA5k7g`,
	"actionstStack": `lITyfaJsDYjVxpVosTiX`,
	"action": `AIB6RoC4Wx2fUrHcxyR5`
};
export default ___CSS_LOADER_EXPORT___;
