export var mapFeilds = [{
        label: "SALES",
        value: "SALES"
    }, {
        label: "UNITS",
        value: "UNITS"
    }, {
        label: "DESCRIPTION",
        value: "DESCRIPTION"
    }, {
        label: "COST",
        value: "COST"
    }, {
        label: "PROFIT",
        value: "PROFIT"
    }, {
        label: "EFFECTIVE PRICE",
        value: "EFFECTIVE PRICE"
    }, {
        label: "DEPTH",
        value: "DEPTH"
    }];
export var segmentOptions = [{
        label: "SALES",
        value: "SALES"
    }, {
        label: "UNITS",
        value: "UNITS"
    }, {
        label: "DESCRIPTION",
        value: "DESCRIPTION"
    }, {
        label: "TIME",
        value: "TIME"
    }, {
        label: "COST",
        value: "COST"
    }];
export var defaultSelect = {
    label: "Select",
    value: ""
};
export var mapDataFeilds = [{
        id: 0,
        name: "Quantity Sold",
        feild: [],
        dropDownVal: "",
        mappedTo: "Financial Fields",
        toolTipText: "The field that represents units sold.",
        isRequired: true,
        isValueAvailable: false
    }, {
        id: 1,
        name: "Gross Sales",
        feild: [],
        dropDownVal: "",
        mappedTo: "Financial Fields",
        toolTipText: "This field represents regular retail price * units sold",
        isRequired: true,
        isValueAvailable: false
    }, {
        id: 2,
        name: "Gross Profit",
        feild: [],
        dropDownVal: "",
        mappedTo: "Financial Fields",
        toolTipText: "The field that represents gross profit. This should be (regular retail price - cost per unit) * units sold",
        isRequired: true,
        isValueAvailable: false
    }, {
        id: 3,
        name: "Cost",
        feild: [],
        dropDownVal: "",
        mappedTo: "Financial Fields",
        toolTipText: "The field that represents per unit cost",
        isRequired: true,
        isValueAvailable: false
    }, {
        id: 4,
        name: "Regular Retail Price",
        feild: [],
        dropDownVal: "",
        mappedTo: "Price Feilds",
        toolTipText: "The field that represents the white tag price (non discounted)",
        isRequired: true,
        isValueAvailable: false
    }, {
        id: 5,
        name: "Effective Price",
        feild: [],
        dropDownVal: "",
        mappedTo: "Price Feilds",
        toolTipText: "The field that represents the out the door, discounted price, which takes into account the various promotions or coupons being run",
        isRequired: false,
        isValueAvailable: false
    }, {
        id: 6,
        name: "Discount Percent",
        feild: [],
        dropDownVal: "",
        mappedTo: "Price Feilds",
        toolTipText: "The percentage difference between regular retail price and effective price. This percentage is typically the discount that is offered to the customer (e.g., 15% off). This may include the effect of multiple promotion types.",
        isRequired: false,
        isValueAvailable: false
    }, {
        id: 7,
        name: "Unique ID",
        feild: [],
        dropDownVal: "",
        mappedTo: "Time Feilds",
        toolTipText: "The unique record that will be optimized. Typically this is simply an item ID or a concatenation of an item ID and store ID",
        isRequired: true,
        isValueAvailable: false
    }, {
        id: 8,
        name: "Item Description",
        feild: [],
        dropDownVal: "",
        mappedTo: "Time Feilds",
        toolTipText: "The field that includes the description of each item (e.g., small french fries)",
        isRequired: true,
        isValueAvailable: false
    }, {
        id: 9,
        name: "Time",
        feild: [{
                label: "TIME",
                value: "TIME"
            }],
        dropDownVal: "TIME",
        mappedTo: "Time Feilds",
        toolTipText: "The column that represents time, formatted as YYYY-MM-DD",
        isRequired: false,
        isValueAvailable: true
    }, {
        id: 10,
        name: "Channel",
        feild: [],
        dropDownVal: "",
        mappedTo: "Product Feilds",
        toolTipText: "The different pathways of sales (online, in-store, delivery)",
        isRequired: false,
        isValueAvailable: false
    }, {
        id: 11,
        name: "Product hierarchy Fields",
        feild: [],
        dropDownVal: "",
        mappedTo: "Multiple Feilds",
        toolTipText: "",
        isRequired: false,
        isValueAvailable: false
    }, {
        id: 12,
        name: "Store heirarchy Fields",
        feild: [],
        dropDownVal: "",
        mappedTo: "Multiple Feilds",
        toolTipText: "",
        isRequired: false,
        isValueAvailable: false
    }];
export var datasets = [{
        name: "Input Data",
        label: "This file should contain transactional information along with product, channel, and store hierarchy columns. Below are the critical fields:",
        type: "inputDataset",
        submit: "Select",
        header: "Forecast ID",
        points: [{
                label: "Product information:",
                value: "Unique ID, product description"
            }, {
                label: "Time:",
                value: "TIME (in YYYY-MM-DD format)"
            }, {
                label: "Prices information:",
                value: "Regular Retail Price, Effective Price"
            }, {
                label: "Financials:",
                value: "Gross Sales, Units Sold, Gross Profit, Cost"
            }],
        example_title: "Example Input Data ",
        example_label: "You can download an example and use it as a template for your own file."
    }];
